import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { Layout } from "../../ReusableComponents/Wrapper/Layout"

import * as poseDetection from '@tensorflow-models/pose-detection';
import * as tf from '@tensorflow/tfjs-core';
// Register one of the TF.js backends.
import '@tensorflow/tfjs-backend-webgl';
// import '@tensorflow/tfjs-backend-wasm';

const scoreThreshold = 0.6;

const defaultImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"

type ICategoryItem = {
    name: string,
    img: string
}

type ICategory = {
    category: string,
    items: Array<ICategoryItem>
}

const CategoryHip = "Hip"
const CategoryHipTestFlexion = "Flexion"
const CategoryHipTestAdduction = "Adduction"
const CategoryHipTestSquat = "Squat"

const CategoryLowerBack = "Lower Back"
const CategoryLowerBackSitAndReachTest = "Modified Sit And Reach"

const availableTests: Array<ICategory> = [{
    category: CategoryHip,
    items: [{
        name: CategoryHipTestFlexion,
        img: "/images/hip_flexion.jpeg"
    }, {
        name: CategoryHipTestAdduction,
        img: "/images/hip_abduction.jpeg"
    },
    {
        name: CategoryHipTestSquat,
        img: "/images/hip_squat.jpeg"
    }]
},
{
    category: CategoryLowerBack,
    items: [
        {
            name: CategoryLowerBackSitAndReachTest,
            img: "/images/sitting_squat_reaching.jpeg"
        }
    ]
}]

const ignoreList = [
    "left_ear", "left_eye", "left_eye_inner", "left_eye_outer",
    "left_pinky", "mouth_left", "mouth_right", "nose",
    "left_index", "left_foot_index",
    "right_ear", "right_eye", "right_eye_inner", "right_eye_outer",
    "right_pinky", "right_index", "right_foot_index",
]

const hipFlexionConnections = {
    "shoulder": ["hip"],
    "hip": ["shoulder", "knee"],
    "knee": ["hip"]
}

const hipAbductionConnections = {
    "hip": ["hip", "knee"],
    "knee": ["hip"]
}

const hipSquatConnections = {
    "shoulder": ["hip"],
    "hip": ["shoulder", "knee"],
    "knee": ["hip"]
}

type IDropDown = {
    onItemChosen: (category: string, item: ICategoryItem) => void
}

const DropDown = ({ onItemChosen }: IDropDown) => {

    const [show, setShow] = useState(false)

    return (
        <div className="relative inline-block text-left w-full">
            <div>
                <button type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button"
                    aria-expanded="true" aria-haspopup="true"
                    onClick={() => setShow(!show)}>
                    Choose Test
                    <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>


            {show && (
                <div className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                    {availableTests.map((row, idx) => {
                        return (
                            <div className="py-1" role="none" key={`${row.category}-${idx}`}>
                                <span className="block px-4 py-2" role="menuitem" id="menu-item-0">{`${row.category} Flexibility Tests`}</span>
                                {row.items.map((item, idx) => {
                                    return (
                                        <span className="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                                            role="menuitem" id="menu-item-0"
                                            key={`${item.name}-${idx}`}
                                            onClick={() => {
                                                setShow(false)
                                                onItemChosen(row.category, item)
                                            }}>
                                            {`${item.name} test`}
                                        </span>
                                    )
                                })}

                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

const modelType = poseDetection.SupportedModels.BlazePose

let poseDetector: poseDetection.PoseDetector;

const createPoseLandmarker = async () => {
    await tf.ready()

    poseDetector = await poseDetection.createDetector(modelType, {
        runtime: "tfjs",
        enableSmoothing: true,
        modelType: "full"
    });

    /*
    //low accuracy
    poseDetector = await poseDetection.createDetector(modelType, {
        enableSmoothing: true,
        modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING
    });

    poseDetector = await poseDetection.createDetector(modelType, {
        enableSmoothing: true,
        modelType: poseDetection.movenet.modelType.SINGLEPOSE_THUNDER
    });
    */
};

export const PoseDetection = () => {

    const [fileSelected, setFileSelected] = useState<File | undefined>()
    const [imageLoaded, setImageLoaded] = useState<File | undefined>()
    const [testChosen, setTestChosen] = useState<{ category: string, item: ICategoryItem } | undefined>()

    const canvasDestRef = useRef<HTMLCanvasElement>(null)
    const canvasSourceRef = useRef<HTMLCanvasElement>(null)
    const [isLoading, setLoading] = useState(true)
    const [inferencedText, setInferencedText] = useState('')

    useEffect(() => {
        setLoading(true)
        createPoseLandmarker().then(() => setLoading(false))

        return () => {
            poseDetector.dispose()
        }
    }, [])

    const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files || []
        if (files.length === 0) {
            return
        }

        setFileSelected(files[0])

        runInference(files[0], canvasDestRef.current!, false)
    }

    const onTestChosen = async (category: string, item: ICategoryItem) => {
        setTestChosen({ category: category, item: item })
        const blob = await fetch(item.img).then(r => r.blob());

        runInference(blob, canvasSourceRef.current!, true)
    }

    const drawKeypoints = (
        keypoints: poseDetection.Keypoint[],
        ctx: CanvasRenderingContext2D,
        isMaster: boolean,
    ) => {
        ctx.fillStyle = isMaster ? 'White' : 'Green';
        ctx.strokeStyle = isMaster ? 'Green' : 'White';
        ctx.lineWidth = 2;
        for (let i = 0; i < keypoints.length; i++) {
            drawKeypoint(keypoints[i], ctx);
        }
    }

    const drawKeypoint = (
        keypoint: poseDetection.Keypoint,
        ctx: CanvasRenderingContext2D,
    ) => {

        const radius = 4;
        if (!ignoreList.includes(keypoint.name!) && keypoint.score! >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, radius, 0, 2 * Math.PI);
            ctx.fill(circle);
            ctx.stroke(circle);
        } else {
            console.log("drawKeypoint", keypoint)
        }
    }

    const drawSkeleton = (
        named: { [key: string]: number },
        keypoints: poseDetection.Keypoint[],
        ctx: CanvasRenderingContext2D,
        isMaster: boolean
    ) => {
        let color: string = "#fff"

        if (isMaster) {
            color = "Green"
        }

        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;

        poseDetection.util.getAdjacentPairs(modelType)
            .forEach(([i, j]) => {

                const kp1 = keypoints[i];
                const kp2 = keypoints[j];

                if (
                    !ignoreList.includes(kp1.name!) &&
                    !ignoreList.includes(kp2.name!) &&
                    kp1.score! >= scoreThreshold &&
                    kp2.score! >= scoreThreshold
                ) {

                    if (!isMaster) {
                        color = "#fff"

                        if (testChosen!.category === CategoryLowerBack && testChosen!.item.name === CategoryLowerBackSitAndReachTest) {
                            const distance = calculateReachHandToeDistance(named, keypoints)

                            console.log(`drawing skeleton from ${kp1.name!} -> ${kp2.name!}`, distance)

                            if (distance < 0) {
                                color = "Green"
                            } else if (distance < 30) {
                                color = "orange"
                            } else {
                                color = "red"
                            }
                        }
                        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestSquat) {
                            const parts1 = kp1.name!.split("_")
                            const parts2 = kp2.name!.split("_")
                            const connections = hipSquatConnections[parts1[1]] || [""]
                            console.log(`drawing skeleton from ${parts1[0]}:${parts1[1]} -> ${parts2[1]}`, connections.includes(parts2[1]))

                            if (connections.includes(parts2[1])) {
                                const angle = calculateHipFlexionAngle(named, keypoints, parts1[0])

                                if (angle > 70) {
                                    color = "Green"
                                } else if (angle > 50) {
                                    color = "orange"
                                } else {
                                    color = "red"
                                }

                                console.log(`drawing skeleton: color: ${color}, angle: ${angle}`)
                            }
                        }
                        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestFlexion) {
                            const parts1 = kp1.name!.split("_")
                            const parts2 = kp2.name!.split("_")
                            const connections = hipFlexionConnections[parts1[1]] || [""]
                            console.log(`drawing skeleton from ${parts1[0]}:${parts1[1]} -> ${parts2[1]}`, connections.includes(parts2[1]))

                            if (connections.includes(parts2[1])) {
                                const angle = calculateHipFlexionAngle(named, keypoints, parts1[0])

                                if (angle > 150) {
                                    color = "#fff"
                                } else if (angle > 120) {
                                    color = "red"
                                } else if (angle > 90) {
                                    color = "orange"
                                } else {
                                    color = "Green"
                                }

                                console.log(`drawing skeleton: color: ${color}, angle: ${angle}`)
                            }
                        }
                        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestAdduction) {
                            const parts1 = kp1.name!.split("_")
                            const parts2 = kp2.name!.split("_")
                            const connections = hipAbductionConnections[parts1[1]] || [""]
                            console.log(`drawing skeleton from ${kp1.name!} -> ${kp2.name!}`, connections.includes(parts2[1]))

                            if (connections.includes(parts2[1])) {
                                const langle = calculateHipAdductionAngle(named, keypoints, "left")
                                const rangle = calculateHipAdductionAngle(named, keypoints, "right")

                                const angle = Math.max(langle, rangle)
                                let colorUpdate = true

                                if (langle === angle && ("right_knee" === kp1.name! || "right_knee" === kp2.name!)) {
                                    colorUpdate = false
                                }
                                else if (rangle === angle && ("left_knee" === kp1.name! || "left_knee" === kp2.name!)) {
                                    colorUpdate = false
                                }

                                console.log(`drawing skeleton: `, langle, rangle, kp1.name!, kp2.name!, colorUpdate, angle)
                                if (colorUpdate) {
                                    if (angle > 150) {
                                        color = "Green"
                                    } else if (angle > 110) {
                                        color = "orange"
                                    } else {
                                        color = "red"
                                    }
                                }

                                console.log(`drawing skeleton: color: ${color}, angle: ${angle}`)
                            }
                        }

                        ctx.fillStyle = color;
                        ctx.strokeStyle = color;
                    }

                    ctx.beginPath();
                    ctx.moveTo(kp1.x, kp1.y);
                    ctx.lineTo(kp2.x, kp2.y);
                    ctx.stroke();
                }
            });
    }

    const findAngle = (
        A: poseDetection.Keypoint,
        B: poseDetection.Keypoint,
        C: poseDetection.Keypoint,
    ) => {
        const AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
        const BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
        const AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
        const rads = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
        return rads * 180 / Math.PI
    }

    const calculateDistance = (
        A: poseDetection.Keypoint,
        B: poseDetection.Keypoint,
    ) => {
        let a = A.x - B.x
        let b = A.y - B.y

        return Math.sqrt((a * a) + (b * b));
    }

    const calculateReachHandToeDistance = (
        named: { [key: string]: number },
        keypoints: poseDetection.Keypoint[],
    ) => {
        const leftHandIdx = keypoints[named["left_index"]]
        const leftToeIdx = keypoints[named["left_foot_index"]]

        const rightHandIdx = keypoints[named["right_index"]]
        const rightToeIdx = keypoints[named["right_foot_index"]]

        const left = leftHandIdx.x - leftToeIdx.x
        const right = rightHandIdx.x - rightToeIdx.x
        const distance = Math.max(left, right)

        console.log("calculateReachHandToeDistance", left, right, distance)

        return distance
    }

    const calculateHipFlexionAngle = (
        named: { [key: string]: number },
        keypoints: poseDetection.Keypoint[],
        which: string
    ) => {

        const p1 = keypoints[named[`${which}_knee`]]
        const p2 = keypoints[named[`${which}_hip`]]
        const p3 = keypoints[named[`${which}_shoulder`]]

        return findAngle(p1, p2, p3)
    }

    const calculateHipAdductionAngle = (
        named: { [key: string]: number },
        keypoints: poseDetection.Keypoint[],
        which0: string
    ) => {

        const which1 = which0 === "left" ? "right" : "left"
        const p1 = keypoints[named[`${which1}_hip`]]
        const p2 = keypoints[named[`${which0}_hip`]]
        const p3 = keypoints[named[`${which0}_knee`]]

        return findAngle(p1, p2, p3)
    }

    const updateInferencedData = async (
        named: { [key: string]: number },
        keypoints: poseDetection.Keypoint[],
    ) => {
        let text = `${testChosen!.category} ${testChosen!.item.name} test \n`

        if (testChosen!.category === CategoryLowerBack && testChosen!.item.name === CategoryLowerBackSitAndReachTest) {
            const distance = calculateReachHandToeDistance(named, keypoints)

            text = text + `Distance observed ${distance.toFixed(0)} pts\n`

            if (distance < 0) {
                text = text + "Observation: You are an expert"
            } else if (distance < 30) {
                text = text + "Observation: Great going!"
            } else {
                text = text + "Observation: Scope for improvement"
            }
        }
        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestSquat) {
            const angle = calculateHipFlexionAngle(named, keypoints, "left")

            text = text + `Hip angle observed ${angle.toFixed(0)}°\n`

            if (angle > 70) {
                text = text + "Observation: You are an expert"
            } else if (angle > 50) {
                text = text + "Observation: Great going!"
            } else {
                text = text + "Observation: Scope for improvement"
            }
        }
        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestFlexion) {
            const langle = calculateHipFlexionAngle(named, keypoints, "left")
            const rangle = calculateHipFlexionAngle(named, keypoints, "right")

            const angle = langle > 150 ? rangle : langle
            const side = langle > 150 ? "Right" : "Left"

            text = text + `${side} Hip angle observed ${angle.toFixed(0)}°\n`

            if (angle > 120) {
                text = text + "Observation: Scope for improvement"
            } else if (angle > 90) {
                text = text + "Observation: Great going!"
            } else {
                text = text + "Observation: You are an expert"
            }
        }
        else if (testChosen!.category === CategoryHip && testChosen!.item.name === CategoryHipTestAdduction) {
            const langle = calculateHipAdductionAngle(named, keypoints, "left")
            const rangle = calculateHipAdductionAngle(named, keypoints, "right")

            const angle = Math.max(langle, rangle)

            const side = angle === langle ? "Left" : "Right"

            text = text + `${side} Hip angle observed ${angle.toFixed(0)}°\n`

            if (angle > 150) {
                text = text + "Observation: You are an expert"
            } else if (angle > 110) {
                text = text + "Observation: Great going!"
            } else {
                text = text + "Observation: Scope for improvement"
            }
        }

        setInferencedText(text)
    }

    const runInference = async (
        file: Blob | MediaSource,
        canvasEl: HTMLCanvasElement,
        isMaster: boolean,
    ) => {

        const canvasCtx = canvasEl.getContext("2d")!

        const img = new Image()
        img.onload = async () => {

            canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height)

            let width = img.width;
            let height = img.height;

            // Change the resizing logic
            if (width > height) {
                if (width > canvasEl.width) {
                    height = height * (canvasEl.width / width);
                    width = canvasEl.width;
                }
            } else {
                if (height > canvasEl.height) {
                    width = width * (canvasEl.height / height);
                    height = canvasEl.height;
                }
            }

            const dx = canvasEl.width > width ? (canvasEl.width - width) / 2 : 0

            const dy = canvasEl.height > height ? (canvasEl.height - height) / 2 : 0

            console.log("drawing", dx, dy, width, height)
            canvasCtx.drawImage(img, dx, dy, width, height)

            const imageData = canvasCtx.getImageData(0, 0, canvasEl.width, canvasEl.height)

            poseDetector.reset()
            const poses = await poseDetector.estimatePoses(imageData)

            if (!poses || poses.length === 0 || poses.length > 1 || poses[0].keypoints === null) {
                return
            }

            const named = poseDetection.util.getKeypointIndexByName(modelType)

            drawKeypoints(poses[0].keypoints, canvasCtx, isMaster)
            drawSkeleton(
                named,
                poses[0].keypoints,
                canvasCtx,
                isMaster)

            if (!isMaster) {
                updateInferencedData(named, poses[0].keypoints)
            }
        }

        img.src = URL.createObjectURL(file)
    }

    // const onImageFileLoaded = () => {
    //     if (!fileSelected || fileSelected === imageLoaded) {
    //         return
    //     }

    //     // const imgEl = document.getElementById("chosen_image")
    //     // const allCanvas = imgEl!.parentElement!.getElementsByTagName("canvas");
    //     // for (let i = 0; i < allCanvas.length; i++) {
    //     //     imgEl!.parentElement!.removeChild(allCanvas[i])
    //     // }

    //     setImageLoaded(fileSelected)

    //     runInference()
    // }

    if (isLoading) {
        return (
            <Layout>
                <div className="w-full flex flex-row h-full p-2 px-16 mt-24 items-center justify-center">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="ml-4">Loading Models...</span>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="w-full flex flex-row mt-24 px-16 flex-wrap justify-between">
                <div className="flex flex-col w-72 text-center">
                    <span className="text-lg">Selected Test</span>
                    {testChosen && (
                        <span>{`${testChosen.category}->${testChosen.item.name} Flexibility Test`}</span>
                    )}
                    <div className="mt-2 w-full">
                        <DropDown onItemChosen={onTestChosen} />
                    </div>

                    {testChosen && (
                        <div className="w-72 mt-8" >
                            <canvas
                                id="canvas"
                                ref={canvasSourceRef}
                                width="300" height="300"
                                className="rounded-lg"></canvas>
                        </div>
                    )}

                </div>

                <div className="flex flex-col w-96 items-center ml-16">
                    <div className="flex flex-col w-full items-center">
                        <span className="text-lg">Selected Image</span>

                        <input type="file" accept="image/jpeg,image/png" id="choose_file"
                            hidden onChange={onFileSelected}
                            disabled={!testChosen} />

                        <label htmlFor={"choose_file"}
                            className="bg-green-500 hover:bg-green-600 border-white rounded-lg p-2 w-2/3 text-white cursor-pointer text-center mt-4 ">
                            {!testChosen ? 'Choose Test First' : 'Choose Image file'}
                        </label>
                    </div>

                    <div className="flex flex-col items-center">
                        <div className="relative mt-12">
                            <img id="chosen_image"
                                className={"w-96 h-96 mt-2 rounded-xl " + (imageLoaded ? "object-contain" : "object-cover")}
                                hidden={!!fileSelected}
                                src={defaultImg}></img>
                            <canvas
                                id="canvas"
                                ref={canvasDestRef}
                                hidden={!fileSelected}
                                width="300" height="300"
                                className="rounded-lg"></canvas>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-96 text-center ml-16">
                    <span className="text-lg">Inference</span>
                    <pre className="bg-gray-400 w-full h-96 mt-2 text-start p-4">
                        {inferencedText}
                    </pre>
                </div>
            </div>
        </Layout>
    )
}

