import React from "react";

import PropTypes from "prop-types";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

const Header = (props: { title?: string }) => {
    return (
        <div
            className='fixed top-0 right-0 w-full flex justify-start items-center zm:px-5 zm:py-4 xl:px-6 xl:py-8 zm:h-16 sm:h-88 bg-white text-black zm:z-50'
            role='navigation'
        >
            <a href='https://myyogateacher.com/'>
                <picture className='icon zm:hidden lg:block cursor-pointer xl:w-64' width='232' height='28'>
                    <source srcSet='https://images.myyogateacher.com/MYT_Logo.svg' type='image/webp' />
                    <img
                        src='https://images.myyogateacher.com/MYT_Logo.svg'
                        alt='logo'
                        width='256'
                        height='28'
                    />
                </picture>
                <picture className='icon zm:w-10 zm:block lg:hidden' alt='logo' width='36' height='20'>
                    <source
                        width='36'
                        height='20'
                        srcSet='https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png'
                        type='image/webp'
                        alt='mobile-header-icon'
                    />
                    <img
                        width='36'
                        height='20'
                        src='https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png'
                        alt='mobile-header-icon'
                    />
                </picture>
            </a>

            <span className="float-start ml-5 italic">{props.title || `**This is a demo app for pose detection**`}</span>
        </div>
    )
}

export const Layout = (props: { title?: string, children: any }) => {
    return (
        <>
            <ToastContainer />
            <Header title={props.title} />
            {props.children}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
};
