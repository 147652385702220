import React, { useEffect, useRef, useState } from "react"
import { Layout } from "../../ReusableComponents/Wrapper/Layout"

import * as poseDetection from '@tensorflow-models/pose-detection';
import * as tf from '@tensorflow/tfjs-core';
// Register one of the TF.js backends.
import '@tensorflow/tfjs-backend-webgl';
// import '@tensorflow/tfjs-backend-wasm';

import {
    PoseLandmarker,
    FilesetResolver,
    DrawingUtils,
    PoseLandmarkerResult,
} from "@mediapipe/tasks-vision"

const scoreThreshold = 0.6;

const canvasWidth = 640
const canvasHeight = 480

let recorderRef: MediaRecorder

const modelType = poseDetection.SupportedModels.BlazePose

// let poseDetector: poseDetection.PoseDetector;

const ignoreList = [
    "left_ear", "left_eye", "left_eye_inner", "left_eye_outer",
    "left_pinky", "mouth_left", "mouth_right", "nose",
    "left_index", "left_foot_index",
    "right_ear", "right_eye", "right_eye_inner", "right_eye_outer",
    "right_pinky", "right_index", "right_foot_index",
]

// const createPoseLandmarker = async () => {
//     await tf.ready()

//     poseDetector = await poseDetection.createDetector(modelType, {
//         runtime: "tfjs",
//         enableSmoothing: true,
//         modelType: "full"
//     });
// };

let isRecording = false
let poseLandmarker: PoseLandmarker
let videoTimestamp = 0
let poseLandmarkerConnections = PoseLandmarker.POSE_CONNECTIONS

const createPoseLandmarker = async () => {
    const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );

    poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_full/float16/latest/pose_landmarker_full.task`,
            // delegate: "CPU"
        },
        runningMode: "VIDEO",
        numPoses: 1
    });

    poseLandmarkerConnections = PoseLandmarker.POSE_CONNECTIONS.filter((x) => {
        return x.end > 10 && x.start > 10
    })

};

export const RecordDetect = () => {

    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const [isLoaded, setIsLoaded] = useState(false)

    const [recordState, setRecordState] = useState(false)
    let chunks = new Array<Blob>()

    useEffect(() => {
        createPoseLandmarker().then(() => setIsLoaded(true))

        return () => {
            poseLandmarker.close()
        }
    }, [])

    const genRecorder = () => {
        recorderRef = new MediaRecorder(canvasRef.current!.captureStream())

        recorderRef.ondataavailable = ({ data }) => {
            if (data.size > 0) {
                chunks.push(data);
            }
        }

        recorderRef.onstop = onRecorderStopped
    }

    const drawKeypoints = (
        keypoints: poseDetection.Keypoint[],
        ctx: CanvasRenderingContext2D,
    ) => {
        ctx.fillStyle = 'Green';
        ctx.strokeStyle = 'White';
        ctx.lineWidth = 2;
        for (let i = 0; i < keypoints.length; i++) {
            drawKeypoint(keypoints[i], ctx);
        }
    }

    const drawKeypoint = (
        keypoint: poseDetection.Keypoint,
        ctx: CanvasRenderingContext2D,
    ) => {

        const radius = 4;
        if (!ignoreList.includes(keypoint.name!) && keypoint.score! >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, radius, 0, 2 * Math.PI);
            ctx.fill(circle);
            ctx.stroke(circle);
        } else {
            console.log("drawKeypoint", keypoint)
        }
    }

    const drawSkeleton = (
        keypoints: poseDetection.Keypoint[],
        ctx: CanvasRenderingContext2D,
    ) => {
        let color: string = "#fff"

        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;

        poseDetection.util.getAdjacentPairs(modelType)
            .forEach(([i, j]) => {

                const kp1 = keypoints[i];
                const kp2 = keypoints[j];

                if (
                    !ignoreList.includes(kp1.name!) &&
                    !ignoreList.includes(kp2.name!) &&
                    kp1.score! >= scoreThreshold &&
                    kp2.score! >= scoreThreshold
                ) {

                    ctx.beginPath();
                    ctx.moveTo(kp1.x, kp1.y);
                    ctx.lineTo(kp2.x, kp2.y);
                    ctx.stroke();
                }
            });
    }


    const onAnimationFrame = async (ctx: CanvasRenderingContext2D) => {
        // const canvasEl = canvasRef.current!

        let width = videoRef.current!.videoWidth
        let height = videoRef.current!.videoHeight

        // Change the resizing logic
        if (width > height) {
            if (width > canvasWidth) {
                height = height * (canvasWidth / width);
                width = canvasWidth
            }
        } else {
            if (height > canvasHeight) {
                width = width * (canvasHeight / height);
                height = canvasHeight
            }
        }

        ctx.clearRect(0, 0, canvasWidth, canvasHeight)

        const dx = canvasWidth > width ? (canvasWidth - width) / 2 : 0
        const dy = canvasHeight > height ? (canvasHeight - height) / 2 : 0

        ctx.drawImage(videoRef.current!, dx, dy, width, height);

        // const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height)

        await runInference(ctx)

        if (isRecording) {
            requestAnimationFrame(ctx)
        }
    }



    const runInference = async (ctx: CanvasRenderingContext2D) => {
        const stamp = (Date.now() - videoTimestamp) + Math.round((Math.random() * 10))

        console.log("runInference: ", stamp)

        const result = poseLandmarker.detectForVideo(canvasRef.current!, stamp)
        // const canvas = document.createElement("canvas");
        // canvas.setAttribute("class", "z-1 absolute pointer-events-none ");
        // canvas.setAttribute("width", imgEl.naturalWidth + "px");
        // canvas.setAttribute("height", imgEl.naturalHeight + "px");
        // canvas.setAttribute('style', `left: 0px; top: 0px; width: ${imgEl.width}px; height: ${imgEl.height}px`)


        // const canvasCtx = canvas.getContext("2d")!
        const drawingUtils = new DrawingUtils(ctx);
        for (let landmark of result.landmarks) {


            drawingUtils.drawLandmarks(landmark.slice(11), {
                color: 'Green',
                radius: 24,
                lineWidth: 2
            });
            drawingUtils.drawConnectors(landmark, poseLandmarkerConnections, {
                lineWidth: 2,
                color: 'white'
            });
        }

    }

    // const addPoseDetection = async (ctx: CanvasRenderingContext2D, imageData: ImageData) => {

    // // poseDetector.reset()

    // const poses = await poseDetector.estimatePoses(imageData)
    // if (!poses || poses.length === 0 || poses.length > 1 || poses[0].keypoints === null) {
    //     return
    // }

    // drawKeypoints(poses[0].keypoints, ctx)
    // drawSkeleton(poses[0].keypoints, ctx)
    // }

    const requestAnimationFrame = async (ctx: CanvasRenderingContext2D) => {
        window.requestAnimationFrame(() => onAnimationFrame(ctx));
    }

    const startRecording = () => {

        genRecorder()
        // canvasElem = document.createElement("canvas")

        videoRef.current!.addEventListener('play', () => {

            const canvasEl = canvasRef.current!

            const ctx = canvasEl.getContext('2d')!

            canvasEl.width = canvasWidth
            canvasEl.height = canvasHeight

            videoTimestamp = Date.now()

            requestAnimationFrame(ctx)
        })

        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then(stream => {
                videoRef.current!.srcObject = stream
                videoRef.current!.play()

                recorderRef!.start(1000)
            })
    }

    const onRecorderStopped = () => {
        recorderRef.ondataavailable = null
        recorderRef.onstop = null

        saveFile()
        chunks = [];
    }

    const stopRecording = () => {
        videoRef.current!.srcObject = null
        recorderRef!.stop()
    }

    const toggleRecording = () => {
        if (!isLoaded) {
            console.log('model is loading')
            return
        }

        if (recordState) {
            isRecording = false
            stopRecording()
        } else {
            isRecording = true
            startRecording()
        }

        setRecordState(!recordState)
    }

    const saveFile = () => {
        const blob = new Blob(chunks);

        const blobUrl = URL.createObjectURL(blob);
        const link: HTMLAnchorElement = document.createElement('a');

        link.style = 'display: none';
        link.href = blobUrl;
        link.download = 'recorded_file.webm';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);
    };

    return (
        <Layout>
            <div className="w-full flex flex-row mt-24 px-16 flex-wrap justify-between">
                <div className="flex flex-col w-72 text-center justify-center">
                    <button className="rounded-lg bg-green-400 hover:bg-green-600 w-full h-auto p-2 mt-5"
                        onClick={toggleRecording}
                    >{recordState ? 'Stop Recording' : 'Start Recording'}</button>
                    {isLoaded ? 'loaded' : 'loading'}
                </div>

                <div className="flex flex-col w-96 text-center">
                    <video className="w-full h-full bg-black rounded-lg mt-5" ref={videoRef} id="video" autoPlay />
                </div>

            </div>

            <div className="w-full flex flex-row mt-24 px-16 flex-wrap justify-between">

                <div className="flex flex-col">
                    <canvas className="w-full h-full bg-black rounded-lg mt-5" ref={canvasRef} id="canvas" />

                </div>
            </div>
        </Layout>
    )
}