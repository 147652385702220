import React, { ChangeEvent, useEffect, useState } from "react"
import { Layout } from "../../ReusableComponents/Wrapper/Layout"

import {
    PoseLandmarker,
    FilesetResolver,
    DrawingUtils,
} from "@mediapipe/tasks-vision"


const defaultImg = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"

const availableTests = [{
    category: "Hip",
    items: ["Flexion", "Adduction", "Rotation", "Squat"],
},
{
    category: "Shoulder",
    items: ["Back Scratch"]
}, {
    category: "Trunk",
    items: [
        "Rotation",
        "Extension Cobra"
    ]
},
{
    category: "Lower Back",
    items: [
        "Modified Sit And Reach"
    ]
}]

type IDropDown = {
    onItemChosen: (category: string, item: string) => void
}

const DropDown = ({ onItemChosen }: IDropDown) => {

    const [show, setShow] = useState(false)

    return (
        <div className="relative inline-block text-left w-full">
            <div>
                <button type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button"
                    aria-expanded="true" aria-haspopup="true"
                    onClick={() => setShow(!show)}>
                    Choose Test
                    <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>


            {show && (
                <div className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                    {availableTests.map((row, idx) => {
                        return (
                            <div className="py-1" role="none">
                                <span className="block px-4 py-2" role="menuitem" id="menu-item-0">{`${row.category} Flexibility Tests`}</span>
                                {row.items.map((item, idx) => {
                                    return <span className="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" id="menu-item-0" onClick={() => {
                                        setShow(false)
                                        onItemChosen(row.category, item)
                                    }}>{`${item} test`}</span>
                                })}

                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

let poseLandmarker: PoseLandmarker;

const createPoseLandmarker = async () => {
    const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );

    poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
        baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_full/float16/latest/pose_landmarker_full.task`,
            delegate: "GPU"
        },
        runningMode: "IMAGE",
        numPoses: 2
    });
};

export const PoseMediaPipDetection = () => {

    const [fileSelected, setFileSelected] = useState<File | undefined>()
    const [imageLoaded, setImageLoaded] = useState<File | undefined>()
    const [testChosen, setTestChosen] = useState<{ category: string, item: string } | undefined>()


    useEffect(() => {
        createPoseLandmarker()
    }, [])


    const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files || []
        if (files.length === 0) {
            return
        }

        setFileSelected(files[0])
    }

    const onTestChosen = (category: string, item: string) => {
        setTestChosen({ category: category, item: item })
    }

    const runInference = async () => {
        console.log("runInference")
        const imgEl: HTMLImageElement = document.getElementById("chosen_image")!

        poseLandmarker.detectForVideo(imgEl, (result) => {
            const canvas = document.createElement("canvas");
            canvas.setAttribute("class", "z-1 absolute pointer-events-none ");
            canvas.setAttribute("width", imgEl.naturalWidth + "px");
            canvas.setAttribute("height", imgEl.naturalHeight + "px");
            canvas.setAttribute('style', `left: 0px; top: 0px; width: ${imgEl.width}px; height: ${imgEl.height}px`)


            imgEl.parentElement!.appendChild(canvas);
            const canvasCtx = canvas.getContext("2d")!
            const drawingUtils = new DrawingUtils(canvasCtx);
            for (const landmark of result.landmarks) {

                drawingUtils.drawLandmarks(landmark, {
                    color: 'Green',
                    radius: 24,
                    lineWidth: 10
                });
                drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS, {
                    lineWidth: 10,
                    color: 'white'
                });
            }
        });
    }

    const onImageFileLoaded = () => {
        if (!fileSelected || fileSelected === imageLoaded) {
            return
        }

        const imgEl = document.getElementById("chosen_image")
        const allCanvas = imgEl!.parentElement!.getElementsByTagName("canvas");
        for (let i = 0; i < allCanvas.length; i++) {
            imgEl!.parentElement!.removeChild(allCanvas[i])
        }

        setImageLoaded(fileSelected)

        runInference()
    }

    return (
        <Layout>
            <div className="w-full flex flex-row mt-24 px-16 flex-wrap  justify-between">
                <div className="flex flex-col w-72 text-center ml-16">
                    <span className="text-lg">Selected Test</span>
                    {testChosen && (
                        <span>{`${testChosen.category}->${testChosen.item} Flexibility Test`}</span>
                    )}
                    <div className="mt-2 w-full">
                        <DropDown onItemChosen={onTestChosen} />
                    </div>
                </div>

                <div className="flex flex-col w-96 items-center">
                    <div className="flex flex-col items-center">
                        <span className="text-lg">Selected Image</span>
                        <div className="relative">
                            <img id="chosen_image"
                                className={"w-96 h-96 mt-2 rounded-xl " + (imageLoaded ? "object-contain" : "object-cover")}
                                onLoad={() => onImageFileLoaded()}
                                src={fileSelected ? URL.createObjectURL(fileSelected) : defaultImg}></img>
                        </div>
                    </div>

                    <input type="file" accept="image/jpeg,image/png" id="choose_file" hidden onChange={onFileSelected} />
                    <label htmlFor={"choose_file"} className="bg-green-500 hover:bg-green-600 border-white rounded-lg p-2 w-2/3 text-white cursor-pointer text-center mt-8 ">
                        Choose Image file
                    </label>

                </div>

                <div className="flex flex-col w-96 text-center ml-16">
                    <span className="text-lg">Inference</span>
                    <pre className="bg-gray-400 w-full h-96 mt-2 text-start p-4">
                        {`test: 0`}
                    </pre>
                </div>
            </div>
        </Layout>
    )
}

