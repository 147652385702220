import React from "react";
import ReactDOM from "react-dom/client";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createFrontloadState } from "react-frontload";
import { HelmetProvider } from "react-helmet-async";
import { App } from "./containers/App";
import "./styles/global.css";

const frontloadState = createFrontloadState.client({
    // inject client impl of api for use in data loading functions.
    // will probably make HTTP calls to the server
    context: {},
    // hydrate state from SSR
    serverRenderedData: window._frontloadData,
    logging: true,
});

// Uncomment the following block while shipping the code to testenv or production
if (import.meta.env.VITE_APP_DEBUG === "true") {
    const domNode = document.getElementById("root");
    const root = createRoot(domNode);
    root.render(
        <HelmetProvider>
            <BrowserRouter>
                <App frontloadState={frontloadState} />
            </BrowserRouter>
        </HelmetProvider>
    );
} else {
    ReactDOM.hydrateRoot(
        document.getElementById("root"),
        <HelmetProvider>
            <BrowserRouter>
                <App frontloadState={frontloadState} />
            </BrowserRouter>
        </HelmetProvider>
    );
}
