import React from "react";
import { Route, Routes } from "react-router-dom";
import { FrontloadProvider } from "react-frontload";
import { ErrorPage } from "../Pages/Error404Page";
import { PoseDetection } from "../Pages/Pose/PoseDetection";
import { PoseMediaPipDetection } from "../Pages/Pose/PoseMediaPipe";
import { ChooseApp } from "../Pages/Home/ChooseApp";
import { FaqBot } from "../Pages/FaqBot";
import { RecordDetect } from "../Pages/Pose/RecordDetect";

// eslint-disable-next-line react/prop-types
export const App = ({ frontloadState }) => {

    return (
        <FrontloadProvider initialState={frontloadState}>
            <Routes>
                <Route path="/" element={<ChooseApp />} />
                <Route path="/mediapipe" element={<PoseMediaPipDetection />} />
                <Route path="/pose" element={<PoseDetection />} />
                <Route path="/faqbot" element={<FaqBot />} />
                <Route path="/record-detect" element={<RecordDetect />} />
                <Route path="/*" element={<ErrorPage />} />
            </Routes>
        </FrontloadProvider>
    );
};
