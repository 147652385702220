import React from "react";

import { Layout } from "../../ReusableComponents/Wrapper/Layout"

export const ErrorPage = () => {
    return (
        <>
            <Layout>
                <div className="pt-100px">
                    <div className="md:pt-110px md::pb-106px pt-51px pb-97.78px flex justify-center items-center">
                        <div className="flex justify-center items-center md:flex-row flex-col xl:pl-0 sm:pl-7 pl-25px pr-25px sm:pr-0">
                            <div className="sm:mr-11">
                                <div className="text-mytDollarRate font-medium sm:text-15px md:leading-18px text-13px text-center sm:text-justify font-inter sm:pb-2.5 pb-5px">
                                    404 Error
                                </div>
                                <div className="font-extrabold font-quincy sm:text-56px text-42px text-black sm:pb-2.5 pb-5px text-center sm:text-left leading-56px">
                                    Page not found
                                </div>
                                <div className="font-inter font-normal text-mytgrey-lite sm:text-xl text-15px md:leading-6 max-w-490px leading-5 text-center sm:text-left ">
                                    Sorry, the page you are looking for doesn't exist or has been
                                    moved.
                                </div>
                                <div className="sm:pt-5 pt-25px sm:block flex justify-center items-center">
                                    <a href="/">
                                        <button className="btn btn-primary whitespace-nowrap h-49px sm:text-17px text-15px leading-22px sm:pt-3.5 sm:px-22px sm:pb-13px pt-12.5px pb-11.5px pl-18px pr-18px">
                                            Back to Home
                                        </button>
                                    </a>
                                    <a href='https://myyogateacher.com/contactus'>
                                        <button className=" sm:text-17px whitespace-nowrap h-49px text-15px leading-22px sm:pt-3.5 sm:px-22px sm:pb-13px pt-12.5px pb-11.5px pl-18px pr-18px text-mytblack bg-mytgray rounded-lg font-semibold xg:ml-18px ml-13px">
                                            Get in touch
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="mt-50px md:mt-0">
                                <div>
                                    <img
                                        src="https://images.myyogateacher.com/web_images/error-page-img.jpg"
                                        alt="error-page"
                                        className="sm:w-585px w-325px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="page-loaded" className="hidden">
                    <span>
                        10decba6-7e28-4a25-a8ef-eeea80dcc73f-0ca68a11-a7c4-4c39-b5a9-f9071a5c6a9c
                    </span>
                </div>
            </Layout>
        </>
    );
};

